<template>
  <div id="userlogin" class="boxsty login">
    <div class="title">
      <div class="text" :class="{ active: logintype == 1 }" @click="changelogin(1)">密码登陆</div>
      <div class="text" :class="{ active: logintype == 2 }" @click="changelogin(2)">验证码登陆</div>
    </div>

    <div class="inputbox inputpad">
      <div class="label">中国 +86</div>
      <el-input class="input inputlabel" type="text" v-model="phone" placeholder="请输入手机号" />
    </div>

    <div class="inputbox" v-if="logintype == 1">
      <el-input class="input" type="password" v-model="password" placeholder="输入登陆密码" @keyup.enter.native="login" show-password />
    </div>

    <div class="inputbox" v-if="logintype == 2">
      <el-input class="input" type="text" v-model="code" placeholder="输入验证码" autocomplete="new-password" @keyup.enter.native="login" />
      <div class="codebox" v-if="!codeshow" @click="getcode">获取验证码</div>
      <div class="codebox" v-else style="color:#3773da;">{{ codetime }}秒后获取</div>
    </div>
    <div class="loginbtn" @click="login">登陆</div>
    <div class="logininfo">
      <div class="chekbox">
        <el-checkbox v-model="checked"></el-checkbox>
        我已阅读并同意
        <router-link to="/agreement" target="_blank">《用户使用协议》</router-link>
      </div>

      <div class="changbox">
        <div class="text" @click="forgetpwd">忘记密码</div>
        <div class="text" @click="toreg">免费注册</div>
      </div>
    </div>
  </div>
</template>

<script>
import { eltips } from '@/util/util';
import { resetRouter } from '@/router/index.js'
import store from '@/store/index.js'
const sm4 = require('sm-crypto').sm4
export default {
  name: 'login',
  data() {
    return {
      phone: '',
      password: '',
      code: '',
      logintype: 1,
      codeshow: false,
      codetime: 59,
      userlogin: '',
      checked: true
    };
  },
  created() {
    this.userlogin = this.$store.state.userlogin;
  },
  methods: {
    //忘记密码
    forgetpwd() {
      this.$store.commit('changeuserlogin', 2);
    },
    //注册
    toreg() {
      this.$store.commit('changeuserlogin', 3);
    },
    handlogin(e) {
      this.login();
    },
    //登录
    login() {
      if (this.checked) {
        let data = {
          phone: this.phone,
          type: this.logintype,
          guard_name: 'api',
          is_customer: 1,
        };

        if (this.logintype == 1) {
          data.password = this.password
        } else {
          data.sms_code = this.code;
        }

        sessionStorage.setItem('guardName', 'api')

        this.axios.post('/api/login', data).then(res => {
          if (res.code == 200) {
            eltips(res.msg, 'success');
            sessionStorage.setItem('token', res.data.access_token);
            sessionStorage.removeItem('role_id')
            sessionStorage.removeItem('pluginroute')
            sessionStorage.removeItem('pluginid')
            this.getInfo();
          } else {
            eltips(res.msg, 'error');
          }
        });
      } else {
        eltips('请阅读用户使用协议', 'warning');
      }
    },
    //获取信息
    getInfo() {
      this.axios.get('/api/me', { params: { guard_name: 'api' } }).then(res => {
        if (res.code == 200) {
          sessionStorage.setItem('userinfo', JSON.stringify(res.data));
          if (res.data.unitid == 2) {
            sessionStorage.setItem('role_id', res.data.roles[0].id);
          }
          if (res.data.shop_id) {
            this.getmeuns();
          } else {
            let routes = [
              {
                component: "createStore/createStore.vue",
                guard_name: "api",
                icon: "el-icon-s-comment",
                layout: "0",
                name: "公告",
                path: "/createStore",
                router_name: "createStore",
                vue_name: "createStore",
                app_level: 0,
              }
            ];
            //储存路由信息
            let type;
            sessionStorage.removeItem('routestext')
            sessionStorage.removeItem('routes')
            sessionStorage.setItem('routes', JSON.stringify(routes));
            if (res.data.unitid) {
              this.$router.replace({ path: '/createStore', query: { unitid: res.data.unitid } });
            } else {
              type = 1;
              this.$router.replace({ path: '/createStore' });
            }
          }
        } else {
          eltips(res.msg, 'error');
        }
      });
    },
    //获取菜单
    getmeuns() {
      this.axios.get('/api/get_menu').then(res => {
        if (res.data.length == 0) {
          return
        }
        resetRouter()
        this.$store.commit('routestatus', 0)
        sessionStorage.removeItem('routestext')
        sessionStorage.removeItem('routes')
        let help = {
          component: "help/helplist.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "指南",
          path: "/help/list",
          router_name: "help",
          vue_name: "helplist",
          app_level: 0,
        }
        let notice = {
          component: "notice/notice.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "公告",
          path: "/notice",
          router_name: "notice",
          vue_name: "notice",
          app_level: 0,
        }
        let noticedetail = {
          component: "notice/noticedetail.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "公告详情",
          path: "/notice/detail",
          router_name: "notice",
          vue_name: "noticedetail",
          app_level: 0,
        }

        let caseroute = {
          component: "case/case.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "案例",
          path: "/case",
          router_name: "case",
          vue_name: "case",
          app_level: 0,
        }

        let casedetail = {
          component: "case/casedetail.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "案例详情",
          path: "/case/detail",
          router_name: "case",
          vue_name: "casedetail",
          app_level: 0,
        }

        let payroute = {
          component: "payapps/payapps.vue",
          guard_name: "api",
          icon: "el-icon-s-comment",
          layout: "2",
          name: "付费",
          path: "/payapps",
          router_name: "payapps",
          vue_name: "payapps",
          app_level: 0,
        }

        res.data.splice(1, 0, help)
        res.data.splice(1, 0, notice)
        res.data.splice(1, 0, noticedetail)
        res.data.splice(1, 0, caseroute)
        res.data.splice(1, 0, casedetail)
        res.data.splice(1, 0, payroute)

        sessionStorage.setItem('routes', JSON.stringify(res.data))

        this.$router.replace({ path: '/index' });
      });
    },
    //切换tab
    changelogin(type) {
      this.logintype = type;
    },
    //获取验证码
    getcode() {
      let that = this;
      this.axios
        .post('/api/send_code', { phone: this.phone })
        .then(res => {
          if (res.code == 200) {
            eltips(res.msg, 'success');
            that.codeshow = true;
            let autocode = setInterval(() => {
              if (that.codetime > 1) {
                that.codetime--;
              } else {
                that.codeshow = false;
                that.codetime = 59;
                clearInterval(autocode);
              }
            }, 1000);
          } else {
            eltips(res.msg, 'error');
          }
        });
    }
  }
};
</script>

<style>
#userlogin .el-input__inner {
  border: none !important;
  border-bottom: 1px solid #dcdee0 !important;
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
#userlogin .el-input__inner:focus {
  border-color: #3773da !important;
}
</style>
<style lang="less" scoped>
.login {
  .title {
    text-align: center;
    margin-bottom: 30px;
    .text {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 40px;
      font-size: 20px;
      color: #969799;
    }
    .active {
      font-size: 26px;
      color: #323233;
    }
  }
  .inputpad {
    padding-top: 30px;
  }
  .inputbox {
    margin-top: 30px;
    position: relative;
    .label {
      font-size: 16px;
      line-height: 47px;
      border-bottom: 1px solid #dcdee0;
      display: inline-block;
    }
    .input {
      font-size: 16px;
      width: 100%;
      height: 48px !important;
      line-height: 48px !important;
      box-sizing: border-box;
    }
    .codebox {
      line-height: 48px;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      color: #999;
    }
    .inputlabel {
      display: inline-block;
      width: 300px;
      float: right;
      flex: 0 0 300px;
    }
    .input:focus {
      border-color: #3773da;
    }
  }
  .loginbtn {
    margin-top: 88px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background: #155bd4;
    border-radius: 5px;
    &:hover {
      background: #3773da;
      cursor: pointer;
    }
  }
  .logininfo {
    overflow: hidden;
    margin-top: 24px;
    font-size: 14px;
    line-height: 14px;
    .chekbox {
      float: left;
      span {
        color: #155bd4;
      }
    }
    .changbox {
      float: right;
      font-size: 14px;
      .text {
        color: #155bd4;
        padding-right: 10px;
        line-height: 14px;
        display: inline-block;
        &:last-child {
          position: relative;
          padding-right: 0;
          padding-left: 10px;
        }
        &:last-child::after {
          content: "";
          width: 1px;
          height: 14px;
          position: absolute;
          left: 0;
          top: 0;
          background: #ebedf0;
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
