<template>
  <div id="userpassword" class="boxsty login">
    <div class="title">
      <div class="text active" v-if="$store.state.userlogin==2">找回密码</div>
      <div class="text active" v-if="$store.state.userlogin==3">现在注册,即可体验免费开店</div>
    </div>

    <div class="inputbox inputpad">
      <div class="label">中国 +86</div>
      <el-input class="input inputlabel" type="text" v-model="phone" autocomplete="off" placeholder="请输入手机号" />
    </div>
    <div class="inputbox">
      <el-input class="input" type="text" v-model="code" autocomplete="new-password" placeholder="输入验证码" />
      <div class="codebox" v-if="!codeshow" @click="getcode">获取验证码</div>
      <div class="codebox" style="color:#3773da;" v-else>{{ codetime }}秒后获取</div>
    </div>
    <div class="inputbox">
      <el-input class="input" type="password" v-model="password" autocomplete="new-password" placeholder="设置密码（8-20个字，包含字母和数字）" show-password />
    </div>

    <div class="loginbtn" v-if="$store.state.userlogin==2" @click="modify">确认修改</div>
    <div class="loginbtn" v-if="$store.state.userlogin==3" @click="register">立即注册</div>

    <div class="logininfo">
      <div class="chekbox" v-if="$store.state.userlogin==3">
        <el-checkbox v-model="checked"></el-checkbox>
        我已阅读并同意
        <!-- <span>《用户使用协议》</span> -->
        <router-link to="/agreement" target="_blank">《用户使用协议》</router-link>
      </div>

      <div class="backlogin">
        已有账号?
        <span @click="tologin">登陆</span>
      </div>
    </div>
  </div>
</template>

<script>
import { eltips } from '@/util/util.js'
export default {
  name: 'password',
  data() {
    return {
      phone: '',
      password: '',
      code: '',
      codeshow: false,
      codetime: 59,
      userlogin: '',
      checked: false,
    };
  },
  created() {
    this.userlogin = this.$store.state.userlogin
  },
  methods: {
    //返回登陆
    tologin() {
      this.$store.commit('changeuserlogin', 1)
    },
    //获取验证码
    getcode() {
      let that = this;
      this.axios.post("/api/send_code",
        {
          phone: this.phone
        },
        {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((res) => {
        console.log(res);
        if (res.code == 200) {
          eltips(res.msg, 'success')
          that.codeshow = true;
          let autocode = setInterval(() => {
            if (that.codetime > 1) {
              that.codetime--;
            } else {
              that.codeshow = false;
              that.codetime = 59;
              clearInterval(autocode);
            }
          }, 1000);
        } else {
          eltips(res.msg, 'error')
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    //注册
    register() {
      if (this.checked) {
        this.axios.post("/api/register", {
          unitid: '',
          phone: this.phone,
          sms_code: this.code,
          password: this.password,
          password_confirmation: this.password,
        },
          {
            header: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message(res.msg)
            this.$store.commit('changeuserlogin', 1)
          } else {
            this.$message(res.msg)
          }
        }).catch((error) => {
          console.log(error);
        })
      } else {
        this.$message('请阅读用户使用协议')
      }
    },
    //	修改密码
    modify() {
      this.axios.post("/api/retrieve", {
        phone: this.phone,
        sms_code: this.code,
        new_password: this.password,
        new_password_confirmation: this.password,
      },
        {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
      ).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message(res.msg)
          this.$store.commit('changeuserlogin', 1)
        } else {
          this.$message(res.msg)
        }
      }).catch((error) => {
        console.log(error);
      })
    }
  }
};
</script>

<style>
#userpassword .el-input__inner {
  border: none !important;
  border-bottom: 1px solid #dcdee0 !important;
  height: 48px !important;
  line-height: 48px !important;
  padding: 0 !important;
  border-radius: 0 !important;
}
#userpassword .el-input__inner:focus {
  border-color: #3773da !important;
}
</style>

<style lang="less" scoped>
.login {
  .title {
    text-align: left;
    .text {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 40px;
      font-size: 20px;
      color: #969799;
    }
    .active {
      font-size: 26px;
      color: #323233;
    }
  }
  .inputpad {
    padding-top: 0;
  }
  .inputbox {
    margin-top: 30px;
    position: relative;
    .label {
      font-size: 16px;
      line-height: 48px;
      border-bottom: 1px solid #dcdee0;
      display: inline-block;
    }
    .input {
      font-size: 16px;
      width: 100%;
      height: 48px;
      line-height: 47px;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid #dcdee0;
    }
    .codebox {
      line-height: 48px;
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      color: #999;
    }
    .inputlabel {
      display: inline-block;
      width: 300px;
      float: right;
      flex: 0 0 300px;
    }
    .input:focus {
      border-color: #3773da;
    }
  }
  .loginbtn {
    margin-top: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    background: #155bd4;
    border-radius: 5px;
    &:hover {
      background: #3773da;
      cursor: pointer;
    }
  }
  .logininfo {
    margin-top: 16px;
    overflow: hidden;
    font-size: 14px;
    .chekbox {
      float: left;
      span {
        color: #155bd4;
        cursor: pointer;
      }
    }

    .backlogin {
      float: right;
      span {
        display: inline-block;
        font-size: 14px;
        margin-left: 10px;
        color: #155bd4;
        cursor: pointer;
      }
    }
  }
}
</style>
